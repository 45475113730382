<template>
  <section fluid>
    <p class="text-h6 font-museo-sans mb-0 secondary--text">
      Ajustes de solicitudes de necesidades
    </p>
    <div>
      <p class="text-body-2 font-museo-sans primary--text">
        Listado de solicitudes
      </p>
      <data-table-component
        v-models:select="perPage"
        v-models:pagina="page"
        :headers="headers"
        :items="items"
        @paginar="paginate"
        :total_registros="totalRows"
      >
        <template v-slot:[`item.estado_solicitud`]="{ item }">
          <v-chip
            label
            :dark="item.estado_solicitud.includes('Descartado')"
            :color="item.color"
          >
            {{ item.estado_solicitud || "" }}
          </v-chip>
        </template>
        <template v-slot:[`item.monto_asignado`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.monto_asignado)
          }}
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="
                  $router.push({
                    name: 'ajuste-necesidades',
                    params: { idSolicitudNecesidad: item.id },
                  })
                "
              >
                <v-icon color="secondary">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span> Editar </span>
          </v-tooltip>
        </template>
      </data-table-component>
    </div>
  </section>
</template>
<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";

import { mapState } from "vuex";
export default {
  name: "SolicitudesNecesidadesView",
  components: {
    DataTableComponent,
  },
  data: () => ({
    headers: [
      {
        text: "Correlativo",
        value: "correlativo",
      },
      {
        text: "Unidad",
        value: "unidad",
      },
      {
        text: "OBS",
        value: "obs",
      },
      {
        text: "Monto($)",
        value: "monto_asignado",
        align: "right",
      },
      {
        text: "Estado",
        value: "estado_solicitud",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    deleteDialog: false,
    solicitudSeleccionada: null,
  }),
  computed: {
    ...mapState(["userInfo", "anioFiscal", "selectedUnidad"]),
  },
  methods: {
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getSolicitudes();
    },
    async getSolicitudes() {
      const filterUnidad = !this.haveRole("ROLE_UNIDAD_CONSOLIDADORA")
        ? { id_unidad: this.selectedUnidad.id }
        : {};

      const { status, data, headers } =
        await this.services.PacServices.getSolicitudesPAC({
          page: this.page,
          per_page: this.perPage,
          id_estado: 4,
          ...filterUnidad,
        });

      if (status == 200) {
        this.items = data;
        this.totalRows = Number(headers.total_rows);
      }
    },
  },
  created() {
    this.getSolicitudes();
  },
};
</script>
